/* Akayla Script */
@font-face {
  font-family: 'AkaylaScript';
  src: local('AkaylaScript'), url(./AkaylaScript.ttf) format('truetype');
}
.akaylaScript {
  font-family: 'AkaylaScript';
}


/* Akayla Script Deco */
@font-face {
  font-family: 'AkaylaScriptDeco';
  src: local('AkaylaScriptDeco'), url(./AkaylaScriptDeco.ttf) format('truetype');
}
.akaylaScriptDeco {
  font-family: 'AkaylaScriptDeco';
}


/* Bellamy Signature */
@font-face {
  font-family: 'BellamySignature';
  src: local('BellamySignature'), url(./BellamySignature.ttf) format('truetype');
}
.bellamy {
  font-family: 'BellamySignature';
}


/* Lieselotte */
@font-face {
  font-family: 'Lieselotte';
  src: local('Lieselotte'), url(./Lieselotte.ttf) format('truetype');
}
.lieselotte {
  font-family: 'Lieselotte';
}
